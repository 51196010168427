import { Button, Card, Col, Divider, Form, Result, Row, Space } from 'antd'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, EditParticipantValues } from '@publica/ui-web-components'
import { GraphQLValue, formValuesToGraphQLValueInputs, graphQLValuesToFormValues } from '@publica/values'

import {
    FieldGroup,
    FieldParameters,
    GetValuesForParticipantDocument,
    LocalizedString,
    useGetValueFormForParticipantSuspenseQuery,
    useUpdateParticipantValuesMutation,
} from '../../../../data'

const fieldGroups: FieldGroup[] = ['PERSONAL_INFORMATION', 'BANKING_INFORMATION']

const useEditProfileStyles = createUseStyles({
    form: {
        marginTop: 40,
        '& .ant-picker': {
            width: '100%',
        },
    },
    submit: {
        textAlign: 'right',
    },
})

type EditProfileProps = {
    participant: {
        id: string
    }
}

export const EditProfile: FC<EditProfileProps> = props => {
    const { data } = useGetValueFormForParticipantSuspenseQuery({
        variables: { participantId: props.participant.id },
    })

    const { t } = useEditProfileTranslation()

    const participant = data?.participant

    if (isNil(participant)) {
        throw new NotFoundError(t('notFound'))
    }

    const operation = participant?.operation

    const navigate = useNavigate()

    const styles = useEditProfileStyles()
    const filteredFields = useMemo(
        () => participant.fields.filter(field => fieldGroups.includes(field.group)),
        [participant.fields]
    )
    const fields = useMemo(() => sortBy(filteredFields, field => field.position), [filteredFields])

    const closed = useMemo(() => {
        // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
        const back = () => navigate('..')
        // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
        const extra = [
            // eslint-disable-next-line react/jsx-no-bind
            <ActionButton key="back" size="middle" onClick={back}>
                {t('back')}
            </ActionButton>,
        ]

        return (
            <Card>
                <Result title={t('closed')} subTitle={t('cannotModify')} extra={extra} />
            </Card>
        )
    }, [navigate, t])

    if (!operationIsOpen(operation)) {
        return closed
    }

    return (
        <Card title={t('update')}>
            <Row justify="center" className={styles.form}>
                <Col span={14}>
                    <EditProfileForm fields={fields} participant={participant} />
                </Col>
            </Row>
        </Card>
    )
}

const useEditProfileTranslation = createUseTranslation({
    FR: {
        closed: `L'opération est clôturée`,
        cannotModify: `Vous ne pouvez plus modifier votre fiche d'informations`,
        update: `Compléter votre fiche d'informations`,
    },
    EN: {
        closed: 'The operation has closed',
        cannotModify: `You can no longer update your information`,
        update: `Update your personal information`,
    },
})

type Field = {
    key: string
    name: LocalizedString
    parameters: FieldParameters
    position?: number | null
    group: FieldGroup
}

type FormSection = {
    id: string
    name: LocalizedString
}

type FieldWithFormSection = Field & {
    formSection?: FormSection | null
}

type Participant = {
    id: string
    values: GraphQLValue[]
    legalEntity: {
        representedBySelf: boolean
    }
}

const useEditProfileFormTranslation = createUseTranslation({
    EN: {
        update: 'Update',
    },
    FR: {
        update: `Mettre à jour vos informations`,
    },
})

type EditProfileFormProps = {
    fields: FieldWithFormSection[]
    participant: Participant
}

const EditProfileForm: FC<EditProfileFormProps> = ({ fields, participant }) => {
    const styles = useEditProfileStyles()
    const { t } = useEditProfileFormTranslation()
    const [form] = Form.useForm()

    const [updateParticipantValuesMutation, { loading }] = useUpdateParticipantValuesMutation({
        refetchQueries: [GetValuesForParticipantDocument],
    })

    const navigate = useNavigate()
    const back = useCallback(() => {
        navigate('..')
    }, [navigate])

    const submit = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        const values = formValuesToGraphQLValueInputs(form.getFieldsValue(), fields)

        await updateParticipantValuesMutation({
            variables: {
                participantValues: [
                    {
                        id: participant.id,
                        values,
                    },
                ],
            },
        })

        navigate('..')
    }, [fields, form, navigate, participant.id, updateParticipantValuesMutation])

    const filteredFields = useMemo(() => fields.filter(field => fieldGroups.includes(field.group)), [fields])

    const values = useMemo(
        () => graphQLValuesToFormValues(participant.values, filteredFields),
        [filteredFields, participant.values]
    )

    return (
        <>
            <EditParticipantValues
                values={values}
                fields={filteredFields}
                form={form}
                selfRepresented={participant.legalEntity.representedBySelf}
            />
            <Divider />
            <div className={styles.submit}>
                <Space>
                    <Button onClick={back} disabled={loading}>
                        {t('cancel')}
                    </Button>
                    <ActionButton inProgress={loading} size="middle" onClick={submit}>
                        {t('update')}
                    </ActionButton>
                </Space>
            </div>
        </>
    )
}
