import { FileOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError } from '@publica/ui-common-utils'
import { OperationHeader, useTabs } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useGetOperationForParticipantSuspenseQuery } from '../../../data'
import { Attachments } from '../attachments'
import { Documents } from '../documents'
import { Profile } from '../profile'

const useParticipationTranslation = createUseTranslation({
    EN: {
        profile: 'Information',
        attachments: 'KYC Documents',
        documents: 'Documents',
    },
    FR: {
        profile: 'Informations Personelles',
        attachments: 'Documents KYC',
        documents: 'Documents',
    },
})

export const Participation: FC = () => {
    const participantId = useParticipantId()
    const { t } = useParticipationTranslation()

    const { data } = useGetOperationForParticipantSuspenseQuery({
        variables: {
            participantId,
        },
    })

    const tabs = useTabs(
        useMemo(() => {
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            const participant = {
                id: participantId,
            }

            return [
                {
                    key: 'profile',
                    label: t('profile'),
                    content: <Profile participant={participant} />,
                    icon: <UserOutlined />,
                },
                {
                    key: 'attachments',
                    label: t('attachments'),
                    content: <Attachments participant={participant} />,
                    icon: <PaperClipOutlined />,
                },
                {
                    key: 'documents',
                    label: t('documents'),
                    content: <Documents participant={participant} />,
                    icon: <FileOutlined />,
                },
            ]
        }, [participantId, t])
    )

    const participant = data.participant

    if (isNil(participant)) {
        throw new NotFoundError(t('notFound'))
    }

    const header = useMemo(
        () => <OperationHeader operation={participant?.operation} participant={participant} />,
        [participant]
    )

    return (
        <Card title={header}>
            <Tabs defaultActiveKey="profile" activeKey={tabs.activeTab} onTabClick={tabs.onChange} items={tabs.items} />
        </Card>
    )
}

const useParticipantId = () => {
    const { participantId } = useParams()
    assert.defined(participantId)
    return participantId
}
