import { Card, Tabs } from 'antd'
import { useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Layout, useTabs } from '@publica/ui-web-components'

import { Operations, Terms } from './pages'
import { Account, AccountInfoGuard } from './pages/account'

const terms = <Terms />
const account = <Account />

export const App: FC = () => {
    const { t } = useAppTranslations()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/operations')
        }
    }, [location, navigate])

    const footerTerms = useMemo(
        () => (
            <div>
                <Link to="/terms">{t('terms')}</Link>
            </div>
        ),
        [t]
    )

    const tabs = useTabs(
        useMemo(
            () => [
                {
                    key: 'operations',
                    label: t('operations'),
                    content: <Operations />,
                },
            ],
            [t]
        )
    )

    const root = useMemo(
        () => (
            <Card>
                <Tabs
                    defaultActiveKey="operations"
                    activeKey={tabs.activeTab}
                    onTabClick={tabs.onChange}
                    items={tabs.items}
                />
            </Card>
        ),
        [tabs.activeTab, tabs.items, tabs.onChange]
    )

    return (
        <Layout footerChildren={footerTerms}>
            <AccountInfoGuard>
                <Routes>
                    <Route path="/terms" element={terms} />
                    <Route path="/account" element={account} />
                    <Route path="*" element={root} />
                </Routes>
            </AccountInfoGuard>
        </Layout>
    )
}

const useAppTranslations = createUseTranslation({
    EN: {
        operations: 'Operations',
        terms: 'Privacy Policy',
    },
    FR: {
        operations: 'Opérations',
        terms: 'Politique de données personnelles',
    },
})

export default App
