import isNil from 'lodash/isNil'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError } from '@publica/ui-common-utils'
import { useAttachmentsWithUploads } from '@publica/ui-web-utils'

import { useGetAttachmentsForParticipantSuspenseQuery } from '../../../data'
import { AttachmentsIndex } from './AttachmentsIndex'

type AttachmentsProps = {
    participant: {
        id: string
    }
}

export const Attachments: FC<AttachmentsProps> = props => {
    const { t } = useAttachmentsTranslation()
    const { data } = useGetAttachmentsForParticipantSuspenseQuery({
        variables: {
            participantId: props.participant.id,
        },
    })

    const participant = data.participant

    if (isNil(participant)) {
        throw new NotFoundError(t('notFound'))
    }

    const operation = participant.operation

    const uploadedAttachments = participant.uploadedAttachments
    const attachments = participant.attachments
    const readonly = !operationIsOpen(operation)

    const attachmentsWithUploaded = useAttachmentsWithUploads(attachments, uploadedAttachments, readonly)

    return <AttachmentsIndex participant={props.participant} attachments={attachmentsWithUploaded} />
}

const useAttachmentsTranslation = createUseTranslation()
