import { Divider } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError } from '@publica/ui-common-utils'
import { ActionButton, ShowParticipantValues } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { Value, WithValues, graphQLValueToValue } from '@publica/values'

import { FieldGroup, useGetValuesForParticipantSuspenseQuery } from '../../../../data'
import { Notifications } from '../../../components'
import { participantEditProfileUrl } from '../../../utils'

const accountOwnerFieldGroups: FieldGroup[] = ['ACCOUNT_HOLDER_INFORMATION']
const infoFieldGroups: FieldGroup[] = ['PERSONAL_INFORMATION', 'BANKING_INFORMATION']

type ShowProfileProps = {
    participant: {
        id: string
    }
}

export const ShowProfile: FC<ShowProfileProps> = props => {
    const styles = utils.useControlsStyles()

    const { t } = useShowProfileCardTranslation()

    const { data } = useGetValuesForParticipantSuspenseQuery({
        variables: { participantId: props.participant.id },
    })

    const participantWithRawValues = data.participant

    if (isNil(participantWithRawValues)) {
        throw new NotFoundError(t('notFound'))
    }

    const participant: WithValues<typeof participantWithRawValues> = useMemo(
        () => ({
            ...participantWithRawValues,
            values: participantWithRawValues.values.map(v => graphQLValueToValue(v) as Value),
        }),
        [participantWithRawValues]
    )

    const operation = participant.operation
    const closed = !operationIsOpen(operation)

    const notifications = useMemo(
        () =>
            participant.notifications.filter(
                notification =>
                    !closed &&
                    notification.__typename === 'MissingValuesNotification' &&
                    notification.group !== 'FINANCIAL_INFORMATION'
            ),
        [closed, participant.notifications]
    )

    const navigate = useNavigate()

    const updatePersonalInformation = useCallback(() => {
        navigate(participantEditProfileUrl(participant))
    }, [navigate, participant])

    const updateAccount = useCallback(() => {
        navigate('/account')
    }, [navigate])

    return (
        <>
            <Notifications notifications={notifications} participant={participant} />
            <div className={styles.controls}>
                <ActionButton size="middle" onClick={updateAccount} disabled={closed}>
                    {t('updateAccountInformation')}
                </ActionButton>
            </div>
            <ShowParticipantValues
                fieldGroups={accountOwnerFieldGroups}
                participant={participant}
                fields={participant.fields}
            />
            <Divider />
            <div className={styles.controls}>
                <ActionButton size="middle" onClick={updatePersonalInformation} disabled={closed}>
                    {t('updatePersonalInformation')}
                </ActionButton>
            </div>
            <ShowParticipantValues
                fieldGroups={infoFieldGroups}
                participant={participant}
                fields={participant.fields}
            />
        </>
    )
}

const useShowProfileCardTranslation = createUseTranslation({
    FR: {
        personalInformation: `Fiche d'informations`,
        updatePersonalInformation: `Mettre à jour votre fiche d'informations`,
        updateAccountInformation: `Mettre à jour vos informations de compte Nexdo`,
    },
    EN: {
        personalInformation: 'Personal information',
        updatePersonalInformation: 'Update your personal information',
        updateAccountInformation: 'Update your account information',
    },
})
