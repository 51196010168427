import * as Icons from '@ant-design/icons'
import { Card, Col, Divider, Row } from 'antd'
import reject from 'lodash/reject'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { To, useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, OperationHeader } from '@publica/ui-web-components'

import { OperationStatus } from '../../../../../data'
import { Notifications } from '../../../../components'
import { Notification } from '../../../../types'
import { participantDocumentsUrl, participantProfileUrl } from '../../../../utils'

type Operation = {
    id: string
    name: string
    status: OperationStatus
}

type Participant = {
    id: string
    info: {
        name: string
    }
    notifications: Notification[]
}

const useOperationCardTranslation = createUseTranslation({
    FR: {
        personalInfo:
            "Vous pouvez consulter et modifier vos informations personnelles utilisées dans le cadre de l'opération.",
        providePersonalInfo: 'Renseigner vos informations personnelles',
        accessDocuments: 'Accéder aux documents',
        documents:
            "Vous pouvez consulter, signer, et télécharger les documents mis à disposition dans le cadre de l'opération.",
    },
    EN: {
        personalInfo: 'You can view and update the personal information used in this operation',
        providePersonalInfo: 'Provide your personal information',
        accessDocuments: 'Access your documents',
        documents: 'You can access, sign, and download the documents provided as part of this operation',
    },
})

type OperationCardProps = {
    operation: Operation
    participant: Participant
}

const verticalDividerStyle = { height: '100%' }

export const OperationCard: FC<OperationCardProps> = ({ operation, participant }) => {
    const title = useMemo(
        () => <OperationHeader operation={operation} participant={participant}></OperationHeader>,
        [operation, participant]
    )

    const notifications = useMemo(
        () =>
            reject(
                participant.notifications ?? [],
                notification =>
                    notification.__typename === 'MissingValuesNotification' &&
                    notification.group === 'FINANCIAL_INFORMATION'
            ),
        [participant.notifications]
    )

    const { t } = useOperationCardTranslation()

    return (
        <Card title={title}>
            <Notifications participant={participant} notifications={notifications} />

            <Row justify="space-evenly">
                <Col span={11}>
                    <OperationAction
                        icon="UserOutlined"
                        description={t('personalInfo')}
                        buttonText={t('providePersonalInfo')}
                        buttonTo={participantProfileUrl(participant)}
                    />
                </Col>
                <Col span={1}>
                    <Divider type="vertical" style={verticalDividerStyle} />
                </Col>
                <Col span={11}>
                    <OperationAction
                        icon="FileOutlined"
                        description={t('documents')}
                        buttonText={t('accessDocuments')}
                        buttonTo={participantDocumentsUrl(participant)}
                    />
                </Col>
            </Row>
        </Card>
    )
}

const useOperationActionStyles = createUseStyles({
    action: {
        textAlign: 'center',
    },
    icon: {
        fontSize: '4em',
        color: colors.grey8,
    },
    buttonText: {
        display: ['block', '!important'],
    },
    description: {
        margin: 20,
        padding: [0, '10%'],
        color: colors.grey7,
    },
})

type OperationActionProps = {
    icon: 'UserOutlined' | 'FileOutlined'
    buttonText: string
    buttonTo: To
    description: string
}

const OperationAction: FC<OperationActionProps> = ({ icon, buttonText, buttonTo, description }) => {
    const styles = useOperationActionStyles()
    const navigate = useNavigate()
    const Icon = Icons[icon]

    const onClick = useCallback(() => {
        navigate(buttonTo)
    }, [buttonTo, navigate])

    return (
        <div className={styles.action}>
            <div>
                <Icon className={styles.icon} />
            </div>
            <p className={styles.description}>{description}</p>
            <div>
                <ActionButton size="middle" onClick={onClick}>
                    {buttonText}
                </ActionButton>
            </div>
        </div>
    )
}
