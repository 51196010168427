import { InMemoryCache } from '@apollo/client'

import '@publica/ui-common-polyfill'
import { boot } from '@publica/ui-web-boot'

import App from './app/app'
import { Login, authStateBuilder } from './auth'
import { possibleTypes } from './data/fragments.generated.json'
import './styles.less'

// FIXME-P1(manager-ui): add legal entity to op name

boot(<App />, {
    auth: {
        stateBuilder: authStateBuilder,
        login: <Login />,
        check: location => {
            switch (location?.pathname) {
                case '/terms':
                    return true
                default:
                    return false
            }
        },
    },
    apollo: {
        cache: new InMemoryCache({
            possibleTypes,
        }),
    },
})
