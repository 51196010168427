import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { EditProfile } from './EditProfile'
import { ShowProfile } from './ShowProfile'

type ProfileProps = {
    participant: {
        id: string
    }
}

export const Profile: FC<ProfileProps> = ({ participant }) => {
    const showProfile = useMemo(() => <ShowProfile participant={participant} />, [participant])
    const editProfile = useMemo(() => <EditProfile participant={participant} />, [participant])

    return (
        <Routes>
            <Route index element={showProfile} />
            <Route path="profile" element={showProfile} />
            <Route path="profile/edit" element={editProfile} />
        </Routes>
    )
}
