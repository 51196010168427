import { CheckCircleFilled, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Card, Col, Divider, Empty, Row } from 'antd'
import saveAs from 'file-saver'
import groupBy from 'lodash/groupBy'
import isError from 'lodash/isError'
// eslint-disable-next-line you-dont-need-lodash-underscore/map
import map from 'lodash/map'
import reject from 'lodash/reject'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { fieldGroupLabel } from '@publica/ui-common-labels'
import { useApiClient } from '@publica/ui-common-network'
import { colors } from '@publica/ui-common-styles'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { UploadWell, UploadedFileRequest, VerticalSpacer } from '@publica/ui-web-components'
import { insertBetweenElements } from '@publica/utils'

import { AttachmentType, FieldGroup } from '../../../../data'
import { AttachmentWithUploaded, UploadedAttachment as TUploadedAttachment } from '../types'

type AttachmentGroups = Partial<Record<FieldGroup, AttachmentWithUploaded[]>>

type AttachmentsIndexProps = {
    attachments: AttachmentWithUploaded[]
    participant: {
        id: string
    }
}

const useAttachmentsTranslation = createUseTranslation({
    FR: {
        noAttachments: `Il n'y a aucun document KYC à fournir`,
    },
    EN: {
        noAttachments: `You do not need to provide any KYC documents`,
    },
})

export const AttachmentsIndex: FC<AttachmentsIndexProps> = ({ attachments, participant }) => {
    const fieldsByGroup: AttachmentGroups = useMemo(
        () => groupBy(attachments, attachment => attachment.group),
        [attachments]
    )

    const { t } = useAttachmentsTranslation()

    if (attachments.length === 0) {
        return <Empty description={t('noAttachments')} />
    }

    return (
        <Row justify="center">
            <Col span={14}>
                <>
                    {map(fieldsByGroup, (attachments: AttachmentWithUploaded[], group: FieldGroup) => (
                        <AttachmentsSection
                            key={group}
                            group={group}
                            attachments={attachments}
                            participant={participant}
                        />
                    ))}
                </>
            </Col>
        </Row>
    )
}

type AttachmentsSectionProps = {
    group: FieldGroup
    attachments: AttachmentWithUploaded[]
    participant: {
        id: string
    }
}

const AttachmentsSection: FC<AttachmentsSectionProps> = ({ group, attachments, participant }) => {
    const title = fieldGroupLabel(group)

    const attachmentUploaders = useMemo(() => {
        const uploaders = attachments.map(attachment => (
            <AttachmentUpload key={attachment.id} attachment={attachment} participant={participant} />
        ))
        return insertBetweenElements(uploaders, idx => <Divider key={`divider-${idx}`} />)
    }, [attachments, participant])

    return (
        <Card title={title}>
            <VerticalSpacer size={15}>{attachmentUploaders}</VerticalSpacer>
        </Card>
    )
}

type AttachmentUploadProps = {
    attachment: AttachmentWithUploaded
    participant: {
        id: string
    }
}

const useAttachmentUploadStyles = createUseStyles({
    upload: {
        color: colors.grey6,
    },
    message: {
        marginTop: 15,
    },
    form: {
        marginTop: 20,
    },
    icon: {
        fontSize: '3em',
        extend: 'upload',
    },
    controls: {
        marginTop: 20,
        textAlign: 'right',
    },
    uploadedAttachmentList: {
        marginTop: 15,
    },
})

const useAttachmentUploadTranslation = createUseTranslation({
    FR: {
        alreadyProvided: `Vous avez déjà fourni ce document KYC. Vous pouvez fournir des documents supplémentaires.`,
        notYetProvided: `Vous n'avez pas encore fourni ce document KYC.`,
    },
    EN: {
        alreadyProvided: 'You have already provided this KYC document. You can provide additional documents.',
        notYetProvided: 'You have not yet provided this KYC document',
    },
})

const AttachmentUpload: FC<AttachmentUploadProps> = ({ attachment, participant }) => {
    const styles = useAttachmentUploadStyles()
    const resolveLocalizedString = useLocalizedStringResolver()

    const [uploadedAttachments, setUploadedAttachments] = useState(attachment.uploadedAttachments)
    const [hasUploadedAttachment, setHasUploadedAttachment] = useState(uploadedAttachments.length > 0)

    useEffect(() => {
        setUploadedAttachments(attachment.uploadedAttachments)
    }, [attachment.uploadedAttachments])

    const onUpload = useCallback((uploadedAttachment: TUploadedAttachment) => {
        setUploadedAttachments(uploadedAttachments => [...uploadedAttachments, uploadedAttachment])
    }, [])

    const onDelete = useCallback((uploadedAttachment: TUploadedAttachment) => {
        setUploadedAttachments(uploadedAttachments => {
            const isKnownUploadedAttachment = uploadedAttachments.find(({ id }) => id === uploadedAttachment.id)
            if (!isKnownUploadedAttachment) {
                return uploadedAttachments
            }
            return reject(uploadedAttachments, ({ id }) => id === uploadedAttachment.id)
        })
    }, [])

    useEffect(() => {
        setHasUploadedAttachment(uploadedAttachments.length > 0)
    }, [uploadedAttachments])

    return (
        <div>
            <AttachmentUploadTitle
                name={resolveLocalizedString(attachment.name)}
                hasUploadedAttachment={hasUploadedAttachment}
            />

            <UploadedAttachmentList uploadedAttachments={uploadedAttachments} onDelete={onDelete} />

            <div className={styles.form}>
                <AttachmentUploadForm attachment={attachment} onUpload={onUpload} participant={participant} />
            </div>
        </div>
    )
}

type UploadedAttachmentListProps = {
    uploadedAttachments: TUploadedAttachment[]
    onDelete?: (uploadedAttachment: TUploadedAttachment) => void
}

const UploadedAttachmentList: FC<UploadedAttachmentListProps> = ({ uploadedAttachments, onDelete }) => {
    const { t } = useAttachmentUploadTranslation()
    const styles = useAttachmentUploadStyles()
    const hasUploadedAttachment = uploadedAttachments.length > 0

    if (!hasUploadedAttachment) {
        return <div className={styles.message}>{t('notYetProvided')}</div>
    }

    return (
        <>
            <div className={styles.message}>{t('alreadyProvided')}</div>
            <ul className={styles.uploadedAttachmentList}>
                {uploadedAttachments.map(uploadedAttachment => (
                    <UploadedAttachment
                        key={uploadedAttachment.id}
                        onDelete={onDelete}
                        uploadedAttachment={uploadedAttachment}
                    />
                ))}
            </ul>
            <Divider />
        </>
    )
}

type UploadedAttachmentProps = {
    uploadedAttachment: TUploadedAttachment
    onDelete?: (uploadedAttachment: TUploadedAttachment) => void
}

const useUploadedAttachmentStyles = createUseStyles({
    delete: {
        color: colors.grey6,
    },
})

const deleteIcon = <DeleteOutlined />

const UploadedAttachment: FC<UploadedAttachmentProps> = ({ uploadedAttachment, onDelete }) => {
    const client = useApiClient()
    const styles = useUploadedAttachmentStyles()

    const download = useAsyncCallback(async () => {
        const download = await client.attachments.download(uploadedAttachment.id)
        saveAs(download.data, uploadedAttachment.fileName)
    }, [client.attachments, uploadedAttachment])

    const deleteAttachment = useAsyncCallback(async () => {
        await client.attachments.delete(uploadedAttachment.id)
        if (onDelete !== undefined) {
            onDelete(uploadedAttachment)
        }
    }, [client.attachments, onDelete, uploadedAttachment])

    return (
        <li>
            <Row justify="space-between">
                <Col>
                    <Button type="link" onClick={download}>
                        {uploadedAttachment.originalFileName ?? uploadedAttachment.fileName}
                    </Button>
                </Col>
                <Col>
                    <Button type="link" onClick={deleteAttachment} className={styles.delete} icon={deleteIcon} />
                </Col>
            </Row>
        </li>
    )
}

type AttachmentUploadTitleProps = {
    name: string
    hasUploadedAttachment: boolean
}

const useAttachmentUploadTitleStyles = createUseStyles({
    icon: {
        paddingRight: 10,
    },
})

const successStyle = { color: colors.success }
const warningStyle = { color: colors.warning }

const AttachmentUploadTitle: FC<AttachmentUploadTitleProps> = ({ name, hasUploadedAttachment }) => {
    const styles = useAttachmentUploadTitleStyles()
    const icon = useMemo(
        () =>
            hasUploadedAttachment ? (
                <CheckCircleFilled style={successStyle} />
            ) : (
                <ExclamationCircleFilled style={warningStyle} />
            ),
        [hasUploadedAttachment]
    )

    return (
        <h3>
            <span className={styles.icon}>{icon}</span> {name}
        </h3>
    )
}

type AttachmentUploadFormProps = {
    participant: {
        id: string
    }
    attachment: AttachmentWithUploaded
    onUpload: (uploadedAttachment: TUploadedAttachment) => void
}

const AttachmentUploadForm: FC<AttachmentUploadFormProps> = ({ attachment, onUpload, participant }) => {
    const accept = useMemo(() => getFileTypesForAttachmentType(attachment.type), [attachment.type])
    const client = useApiClient()

    const onAttachmentUpload = useCallback(
        async ({ file, onProgress }: UploadedFileRequest) => {
            try {
                const response = await client.attachments.upload(
                    {
                        id: attachment.id,
                        participantId: participant.id,
                    },
                    file,
                    progress => {
                        if (progress.total === undefined) {
                            onProgress(undefined)
                        } else {
                            onProgress(Math.round((progress.loaded / progress.total) * 100))
                        }
                    }
                )

                onUpload({
                    id: response.id,
                    fileName: file.name,
                })
            } catch (e) {
                if (isError(e)) {
                    throw e
                } else {
                    throw new Error('Error uploading attachment')
                }
            }
        },
        [attachment.id, client.attachments, onUpload, participant.id]
    )

    return (
        <UploadWell
            multiple
            indeterminate={false}
            accept={accept}
            disabled={attachment.readonly}
            onUpload={onAttachmentUpload}
        />
    )
}

const imageFileTypes = ['image/*', '.pdf'].join(',')
const documentFileTypes = ['.doc', '.docx', '.pdf'].join(',')

const getFileTypesForAttachmentType = (attachmentType: AttachmentType): string => {
    switch (attachmentType) {
        case 'DOCUMENT':
            return documentFileTypes
        case 'IMAGE':
            return imageFileTypes
    }
}
