import isNil from 'lodash/isNil'
import { useMemo } from 'react'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError } from '@publica/ui-common-utils'
import { DocumentTableProps } from '@publica/ui-web-components'

import { DocumentCategory, OperationStatus, useGetDocumentsForParticipantSuspenseQuery } from '../../../data'
import { Notifications } from '../../components'
import { Notification } from '../../types'
import { DocumentsIndex } from './DocumentsIndex'

type DocumentsProps = {
    participant: {
        id: string
    }
}

export const Documents: FC<DocumentsProps> = props => {
    const { t } = useDocumentsTranslation()
    const { data } = useGetDocumentsForParticipantSuspenseQuery({
        variables: { participantId: props.participant.id },
    })

    const participant = data?.participant

    if (isNil(participant)) {
        throw new NotFoundError(t('notFound'))
    }

    const operation = participant?.operation

    return <DocumentsCard operation={operation} participant={participant} />
}

type DocumentsCardProps = {
    operation: {
        id: string
        status: OperationStatus
    }
    participant: {
        id: string
        notifications: Notification[]
        documents: (DocumentTableProps['documents'][number] & { categories: DocumentCategory[] })[]
    }
}

const DocumentsCard: FC<DocumentsCardProps> = ({ operation, participant }) => {
    const documents = participant.documents
    const closed = !operationIsOpen(operation)

    const notifications = useMemo(
        () =>
            participant.notifications.filter(
                notification => !closed && notification.__typename === 'RequiredSignaturesNotification'
            ),
        [participant.notifications, closed]
    )

    return (
        <>
            <Notifications notifications={notifications} participant={participant} />
            <DocumentsIndex documents={documents} operationId={operation.id} />
        </>
    )
}

const useDocumentsTranslation = createUseTranslation()
