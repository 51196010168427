import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { Participation } from '../participation'
import { OperationsIndex } from './OperationsIndex'

const operationsIndex = <OperationsIndex />
const participation = <Participation />

export const Operations: FC = () => {
    return (
        <Routes>
            <Route path="/operations" element={operationsIndex} />
            <Route path="/operations/:participantId/*" element={participation} />
        </Routes>
    )
}
